@import 'fonts';

* {
    box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6, p {
    font-family: 'Graphik Web';
    margin: 0;
}
body {
    background-color: #000000;
    overflow-x: hidden;
}
a {
    text-decoration: none;
}
input:focus {
    outline: none;
}

$P1_WHITE: #ffffff;
$P2_SEAFOAM: #015863;
$P3_TEAL: #16BABE;
$P4_LIGHT_BLUE: #D4F8FF;
$P5_ULTRA_LIGHT_BLUE: #F3FDFF;
$G1_BLACK: #000000;

$MOBILE_BREAKPOINT: '520px';


// ============DATEPICKER STYLES===========
.portal-datepicker {
    background-color: transparent;
    height: 46px;
    width: 170px;
    color: $P1_WHITE;
    cursor: pointer;
	font-size: 24px;
	font-weight: 600;
    line-height: 18px;
    border: none;
    text-align: left;
    transition: all 300ms;
    input:focus {
        outline: none;
    }
    @media (max-width: $MOBILE_BREAKPOINT) {
        width: 130px;
        font-size: 20px;
    }
}
.portal-datepicker:hover {
	color: $P1_WHITE;
    background-color: transparent;
}

.react-datepicker__header {
    background-color: $P1_WHITE;
    
    // Overwrite existing styles
    border-bottom: 1px solid #EFEFEF;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__day-name {
	opacity: 0.5;
	color: $G1_BLACK;
	font-family: 'Graphik Web';
	font-size: 12px;
	font-style: italic;
}

.react-datepicker__day {
	font-family: 'Graphik Web';
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 1px;
    text-align: center;
    color: $G1_BLACK;
    opacity: 0.2;
    
    // Prevents hovering on other elements from changing its size and making calendar jumpy
    border: 1px solid RGBA(0,0,0,0);
    border-radius: 50%;
}

.react-datepicker__day:hover {
    border-radius: 50%;
}

.react-datepicker__day.eventDate {
    color: $P3_TEAL;
    opacity: 1;
    :hover {
        border: 1px solid $P4_LIGHT_BLUE;
        border-radius: 50%;
        background-color: $P5_ULTRA_LIGHT_BLUE;
    }
}

.react-datepicker__day.eventDate:hover {
    border: 1px solid $P4_LIGHT_BLUE;
    border-radius: 50%;
    background-color: $P5_ULTRA_LIGHT_BLUE;
}

.react-datepicker__day--selected {
    color: $P1_WHITE;
    background-color: $P2_SEAFOAM;
    border-radius: 50%;
    opacity: 1;
    :hover {
        border-radius: 50%;
    }
}

.react-datepicker__day--selected.eventDate {
    color: $P1_WHITE;
    background-color: $P2_SEAFOAM;
    border-radius: 50%;
    opacity: 1;
    :hover {
        border-radius: 50%;
    }
}

// ========== END DATEPICKER STYLES ===========